'use client';

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { isDebug } from '../utils/utils';
import { useNavbar } from '../hooks';
import packageJson from '../../package.json';
import type { NextPage } from 'next';
import dynamic from 'next/dynamic';

console.log(`🌏 Dashboard version ${packageJson.version}`);

const AppSectionDynamic = dynamic(() => import('../components/organisms/AppSection'));

const Home: NextPage = () => {
  const [navbarData] = useNavbar();
  const [todaysDate, setTodaysDate] = useState('');

  useEffect(() => {
    async function loadGTM() {
      await import('../utils/tracking').then(module => module.default());
    }

    async function loadDD() {
      await import('../utils/datadog').then(module => module.default());
    }

    if (process.env.NEXT_PUBLIC_USE_GTM === '1') {
      loadGTM();
    }
    if (navbarData) {
      loadDD();
    }
  }, [navbarData]);

  useEffect(() => {
    const loadLibraries = async () => {
      const $ = (await import('jquery')).default;
      const bootstrap = await import('bootstrap');

      // If you really need to attach to window
      // @ts-ignore
      window.$ = $;
      // @ts-ignore
      window.jquery = $;
      // @ts-ignore
      window.bootstrap = bootstrap;

      if (isDebug()) {
        console.log('🌏 Adding Bootstrap and JQuery from npm');
      }
    };

    loadLibraries();
  }, []);

  // Move this to a client component
  useEffect(() => {
    // only render the date on the client to prevent a mismatch between the
    // datetime on the server and client during SSR
    setTodaysDate(dayjs().format('ddd MMM D, YYYY'));
  }, []);

  return (
    <React.Fragment>
      <div className="container my-1" id="jt-app">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-12 mb-2">
            <div className="card border-0 w-100" id="jt-app-card">
              <div id="jt-app-header" className="card-header bg-transparent">
                <div className="row">
                  <div className="col-sm-6 d-flex align-items-center">
                    {navbarData && (
                      <h2 className="mb-sm-0 h5">
                        Welcome, {navbarData.firstName} {navbarData.lastName}
                      </h2>
                    )}
                  </div>
                  <div className="col-sm-6 d-flex justify-content-sm-end align-items-center">
                    <div className="float-right">{todaysDate}</div>
                  </div>
                </div>
              </div>
              <div className="card-body" id="jt-app-body">
                <div className="row">
                  <AppSectionDynamic navbarData={navbarData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
