import React from 'react';
import { isDebug } from './utils/utils';

export function useNavbar(): any[] {
  const [dataFromNavbar, setDataFromNavbar] = React.useState<any>(null);

  React.useEffect(() => {
    let poll: any = null;

    function extractUserInfo(event: CustomEvent) {
      clearInterval(poll);
      return event.detail;
    }
    function handleResponse(event: CustomEvent | Event) {
      setDataFromNavbar(extractUserInfo(event as CustomEvent));
    }

    function pingNavbar() {
      if (isDebug()) {
        console.log('🌏 Pinging user navbar from hook');
      }
      window.dispatchEvent(new CustomEvent('requestuserinfo:jt:navbar'));
    }

    //add event listener
    window.addEventListener('userinfo:jt:navbar', handleResponse);

    poll = setInterval(pingNavbar, 100);

    return () => {
      //remove event listener
      window.removeEventListener('userinfo:jt:navbar', handleResponse);
    };
  }, []);
  return [dataFromNavbar, setDataFromNavbar];
}

export function useDivisionChange(): any[] {
  const [divisionData, setDivisionData] = React.useState<any>(null);

  React.useEffect(() => {
    function handleResponse(event: CustomEvent | Event) {
      setDivisionData((event as CustomEvent).detail);
    }

    //add event listener
    window.addEventListener('divisionchange:jt:navbar', handleResponse);

    return () => {
      //remove event listener
      window.removeEventListener('divisionchange:jt:navbar', handleResponse);
    };
  }, []);
  return [divisionData, setDivisionData];
}
